import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudyiridium.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-iridium-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">                   
                    <div className="col-md-8">
                       <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 1.5}
                            height={pageContext.acf.client_logo.media_details.height / 1.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2023/03/iridium-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="margin-b">
                <div class="container-fluid detail-wrapper">
                    <div className="row">
                        <div className="col-md-6 bgIceberg">
                            <div className="">&nbsp;</div>
                        </div>
                        <div className="col-md-6 copyIceberg">
                            <h3>Challenge</h3>
                            <p>Iridium sought the help of Merit Mile to overcome the challenge of developing a series of Iridium GO! exec advertising campaigns that would appeal to four distinct buyer personas. These included NGO workers, humanitarians, and field researchers; remote and field industry technicians and operators; private aviation and luxury yacht owners and operators; and off-the-grid business professionals and executives.</p>
                            <p>The campaigns had to connect with each of these distinct groups while also presenting a unified look and feel across various digital publications and print formats. Developing a cohesive strategy that could resonate with such diverse audiences was no small feat for Merit Mile, requiring both a creative and strategic approach.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <p>After presenting Iridium with six concepts, the company had a tough decision to make. So tough that they choose three, rather than one concept to move forward with. Merit Mile then refined each of the campaigns for their respective placement in print and digital advertising, which included <strong>Outside Magazine</strong>, <strong>The Robb Report</strong>, and <strong>AOPA Pilot</strong> (Aircraft Owners and Pilots Association).</p>
                        <p className="text-center margin-b"><img class="alignnone size-medium wp-image-4834 aligncenter" src="https://www.meritmile.com/uploads/2023/03/iridium-media-logos.jpg" alt="" className="img-fluid" style={{maxWidth: '95%'}} /></p>
                        <p>To further boost the impact of the campaigns, Merit Mile proposed a series of short video ads that were optimized for social media, allowing Iridium to reach a wider audience. In addition to Iridium’s efforts on external platforms, Merit Mile ensured that each campaign tied back to Iridium's website with matching creative for the Iridium GO! exec product pages. These strategic moves not only drove more traffic to the site but helped to reinforce the brand and the campaigns across all touchpoints.</p>
                        <p>Merit Mile's approach to the Iridium campaign resulted in a successful advertising campaign that resonated with all four buyer personas. By using the creative brief as a guiding tool and taking the time to explore various creative and messaging approaches, the agency was able to provide Iridium with a series of cohesive and effective campaigns that delivered impressive results.</p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2023/03/iridium-creative-01.jpg"
                            className="results-image img-fluid"
                            alt="Iridium Creative"
                            width="616px"
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2023/03/iridium-creative-02.jpg"
                            className="results-image img-fluid"
                            alt="Iridium Creative 1"
                            width="616px"
                        />
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2023/03/iridium-creative-03.jpg"
                            className="results-image img-fluid margin-b"
                            alt="Iridium Creative 3"
                            width="616px"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Deliverables</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-6">
                        <ul>
                            <li>Creative brief</li>
                            <li>Messaging framework</li>
                            <li>Six campaign creative concepts</li>
                            <li>Three final campaigns for production</li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li>Video ads</li>
                            <li>Print ads</li>
                            <li>Digital display ads</li>
                            <li>Website creative, copy, and hero images</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your vertical content marketing, lead generation, and sales goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Iridium%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2023/03/svelazquez.jpg"
                                        alt="Sebastian Velazquez"
                                        className="img-fluid"
                                    />
                                    <h4>Sebastian Velazquez</h4>
                                    <p>
                                        Senior<br />
                                        Art Director<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Iridium%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
